@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Colors */
  --white: #ffffff;
  --black: #000000;
  --grey-100: "#eeeeee";
  --grey-200: "#dedede";
  --grey-300: "#bcbcbc";
  --grey-400: "#646464";
  --red: "#ff325a";
  --orange: "#f2994a";
  --yellow: "#f0de00";
  --green-100: "#219653";
  --green-200: "#27ae60";
  --green-300: "#6fcf97";
  --blue-100: "#2f80ed";
  --blue-200: "#2d9cdb";
  --blue-300: "#56ccf2";
  --purple-100: "#9b51e0";
  --purple-200: "#BB6BD9";

  /* Font Weights */
  --thin: 200;
  --light: 300;
  --normal: 400;
  --semibold: 500;
  --bold: 600;
  --heavy: 700;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

.componentWrapper {
  margin: 40px auto;
  max-width: 80%;
}

/* Text */

.shadowH1 {
  font-weight: var(--bold);
  font-size: 60px;
  letter-spacing: -1px;
  line-height: 60px;
  margin: 10px 0;
  text-shadow: -5px 5px var(--red);
}

.eyebrowTextSmall {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 5px;
}

input {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: none;
}
